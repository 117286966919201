import React from 'react';
import { useScrollFadeIn} from "../../../hooks";

const Section2Tablet = ({handleClick, handleVoiceClick, handleVideoClick, select_type ,
                            UnseMain_tablet}) => {
    const animatedItem = {
        0: useScrollFadeIn('up', 1, 0),
        1: useScrollFadeIn('up', 1, 0.3),
        2: useScrollFadeIn('up', 1, 0.6),
        3: useScrollFadeIn('up', 1, 0.9),
    };
    return (
        <React.Fragment>
            <div className="contents-right"  {...animatedItem[0]}>
                <div className="contents-text" >
                <h1
                    className="contents-base"
                 >
                    언제 어디서나<br></br>
                    운세상담 받을 수 있어요
                </h1>
                <p
                    className="top-contetns-white contents-base"
                >
                    채팅, 음성, 화상을 통해<br/>
                    편리하게 이용할 수 있습니다.
                </p>
                </div>
            </div>
            <div className="contents-left">
                {UnseMain_tablet}
            </div>
            <div className="select-contents-type-box" >
                <div
                    className="select-contents-type contents-base"
                  >
                    <div
                        className="select-type"
                        id="chat"
                        onClick={handleClick}
                        style={(select_type === 'chat' ? {color:'#ffffff'} : {color:'#9a9a9a'})}
                    >
                        <p>채팅상담</p>
                        <div className="select-type-line"
                             style={(select_type === 'chat' ? {display:'block'} : {color:'none'})}
                        />
                    </div>

                    <div
                        className="select-type select-contents-padding"
                        onClick={handleVoiceClick}
                        style={(select_type === "voice" ? {color:'#ffffff'} : {color:'#9a9a9a'})}
                    >
                        <p>음성상담</p>
                        <div className="select-type-line"
                             style={(select_type === 'voice' ? {display:'block'} : {color:'none'})}
                        />
                    </div>
                    <div
                        className="select-type"
                        id="video"
                        onClick={handleVideoClick}
                        style={(select_type === 'video' ? {color:'#ffffff'} : {color:'#9a9a9a'})}
                    >
                        <p>화상상담</p>
                        <div className="select-type-line"
                             style={(select_type === 'video' ? {display:'block'} : {color:'none'})}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Section2Tablet;