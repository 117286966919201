import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import data from '../JSON/section1/data1';
import {  Waypoint  } from 'react-waypoint' ;


const Data1 = ({}) => {
    let [renderLottie, setRenderLottie] = useState(false);

    return (
        <div
            className="lottie thumbs-up sectionimg1"
            id="lottie"
        >
            <Waypoint
                scrollableAncestor = { window }
                fireOnRapidScroll={false} //뷰포트의 모든 항목에 대한 렌더링 시간이 빨라집니다 ==> false
                topOffset='-750px'
                bottomOffset='200px'
                onEnter={() => {
                    setRenderLottie(true);}
                }
                onLeave={
                    () => {
                        setRenderLottie(false);
                    } }
            />
            {renderLottie && <Lottie
                loop={false}
                animationData={data}
                play
                renderer='svg'
            />}
        </div>
    )
};

export default Data1;