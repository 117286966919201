import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import data from '../JSON/section4/data4';
import {Waypoint} from "react-waypoint";



const Data2 = () => {

    let [renderLottie, setRenderLottie] = useState(false);


    return (
        <div
            className="data2img"
        >

            <Waypoint
                // scrollableAncestor = { window }
                fireOnRapidScroll={false}
                topOffset='-750px'
                bottomOffset='200px'
                onEnter={() => {
                    setRenderLottie(true);
                }}
                onLeave={
                    () => {
                        setRenderLottie(false);
                    } }
            />
            {renderLottie &&
            <Lottie
                loop={false}
                animationData={data}
                play
                renderer='svg'
            />
            }
        </div>
    )
};

export default Data2;