import React, { Component } from 'react';
import Header from "./Header.jsx";
import Section1 from "./Section1.jsx";
import Section2 from "./Section2.jsx";
import Section3 from "./Section3.jsx";
import Section4 from "./Section4.jsx";
import Section5 from "./Section5.jsx";
import Section6 from "./Section6.jsx";
import _Footer from "./_Footer.jsx";
import { Element } from 'react-scroll';
import Section7 from "./Section7";
import Footer from "./Footer";



class Index extends Component {
  render() {
    return (
            <div>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div>
                      <Header/>
                        <Section1/>
                          <Element name="Section2">
                            <Section2/>
                          </Element>
                        <Section3 />
                        <Section4/>
                          <Element name="Section5">
                            <Section5/>
                          </Element>
                        {/*<Section6/>*/}
                        <Section7/>
                      <Footer/>
                    </div>
                  </div>
                </div>
              </div>
            </div>              
    );
  }
}

export default Index;

