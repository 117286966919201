import React, { Component } from 'react';
import destiny_img from '../../assets/img_update/mus-logo-horizen.svg'
import '../../assets/css/base/navbar.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/mobile/slidebar.css';
import Sidebar from './sidebar';
import { Link} from "react-scroll";

class Header extends Component {

render(){
    return(   
        <div className="wrap">
        <div className="header">
            <div className="navbar_contents">
            <nav className="navbar">
            <div className="logo"
                 onClick={() => window.location.reload()}>
                <img src={destiny_img}
                    alt="mus_logo"
                    className="mus_logo"
                    onClick={this.scrollToTop}/>
            </div>
            <div className="desktop-menu">
            <ul className="menu">                        
                    <li> 
                    <Link
                    activeClass="active"
                    to="Section2"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration= {500}
                    onSetActive={this.handleSetActive}
                    className="link"
                    > 명운세 소개
                    </Link>
                    </li>                      
                    <li> 
                    <Link
                    activeClass="active"
                    to="Section5"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration= {500}
                    className="link"
                    >
                    이용요금
                     </Link> </li>
                <li>
                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSd567w2dqf6d6Ue-vg6tJPRTD-ZNI0eFLYcENo0sBARn90lYw/viewform?usp=send_form'
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{ color: "#191919", textDecoration: "none"}}
                    >
                     전문가 채용
                    </a>
                </li>
                </ul>
            </div>
            <div className="mobile-slidebar">   
            <Sidebar>
                </Sidebar>
                </div>
            </nav>
            </div>
        </div>        
        </div>


        )
    }
}
export default Header;

