import React, { Component } from 'react';
import '../../assets/css/base/section1.css'
import '../../assets/css/tablet/tablet.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'



function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
      }
      if (/android/i.test(userAgent)) {
        return "Android";
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
      }
        return "unknown";
}


function DetectAndServe() {
  if (getMobileOperatingSystem() == "Android") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
  }
  if (getMobileOperatingSystem() == "iOS") {
    // window.location.href = "itms-apps://itunes.apple.com/app/id1441749378";
    window.location.href = "https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EB%AC%B8-for-%ED%83%80%EB%A1%9C%EB%A7%88%EC%8A%A4%ED%84%B0/id1409500842"
  }
  if (getMobileOperatingSystem() == "Windows Phone") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
  }
  if (getMobileOperatingSystem() == "unknown") {
    window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
  }
};



class Section1 extends Component {

render(){
    return(    
        <div className="size">
            <div className="section1">
                 <div className="section-contents-section1">
                    <div className="contents">
                        <div className="contents-left-section1">
                            <div className="contents-text-top">
                                <div className="contents-contents">
                                    <p className="top-text">
                                        명운세에서 속시원하게
                                    </p>
                                    <p className="top-text">
                                        고민 해결해보세요
                                    </p>
                                    <p className="top-contetns">
                                        정확한 운세분석을 통해<br/>
                                        나아갈 방향을 자세히 알려드리겠습니다.
                                    </p>
                                </div>
                                <div className="top-app">
                                   <a
                                       href="https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                   >
                                     <div className="googleplay_button googleplay"/>
                                   </a>
                                    <a
                                        href="https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EB%AC%B8-for-%ED%83%80%EB%A1%9C%EB%A7%88%EC%8A%A4%ED%84%B0/id1409500842"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="appstore_button appstore button_padding"/>
                                    </a>
                                </div>
                            </div>
                            </div>
                    </div>
                 </div>

                            <div className="contents-right-section1">
                                <div className="sectionimg"/>
                            </div>
                    </div>
                </div>
       
)
}
}

export default Section1;