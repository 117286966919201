import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import data from '../JSON/section5/data5';
import {Waypoint} from "react-waypoint";






const Data3 = () => {

    let [renderLottie, setRenderLottie] = useState(false);

    return (
        <div
            className="thumbs-up sectionimg4"
            id="lottie"
        >
            <Waypoint
                scrollableAncestor = { window }
                fireOnRapidScroll={false}
                topOffset='-450px'
                bottomOffset='200px'
                onEnter={() => {
                    setRenderLottie(true);
                }}
                onLeave={
                    () => {
                        setRenderLottie(false);
                    } }
            />
            {renderLottie &&
            <Lottie
                loop={false}
                animationData={data}
                play
                renderer='svg'

            />}
        </div>
    )
};

export default Data3;