import React, { Component } from "react";
import destiny_img from '../../assets/img_update/mus-logo-horizen.svg'
import '../../assets/css/mobile/slidebar.css';
import '../../assets/css/base.css';
import { Link } from "react-scroll";
import menubtn from '../../assets/img_update/ic_menu_k.svg';
import cancel from '../../assets/img_update/ic_cancel_k.svg';


  class Sidebar extends Component {
      constructor(props) {
          super(props);
              this.state = {
                  menu: false,
              };
      };

      handleClick = (e) => {
          this.setState({
            menu: !this.state.menu
          });
      };


    render(){
        const menu = this.state.menu;
        let menu_btn;
        if ( menu ) {
            menu_btn =
                <img
                    src={cancel}
                    className="cross"
                    onClick={this.handleClick}
                />
        } else {
            menu_btn =
                <img
                    src={menubtn}
                    className="menubtn"
                    onClick={this.handleClick}
                />
        }


        return(           
        <div className="dropmenu">
           <div className="wrap">
            <div className="header ">
            <div className=" size solid">
                <nav className="navbar">
                    <div className="logo"
                        onClick={() => window.location.reload()}>
                            <img src={destiny_img}
                                alt="mus_logo"
                                className="mus_logo"
                                onClick={this.scrollToTop}/>
                    </div>
                <div className="desktop-menu">
                    <div>
                        <div className="button">
                            {menu_btn}
                        </div>
                    </div>
                <div
                     className={(this.state.menu === true ?
                          "menulist" : "menulist_close"
                    )}
                >
                    <ul>
                        <li>
                            <Link
                             activeClass="active"
                             to="Section2"
                             spy={true}
                             smooth={true}
                             offset={-50}
                             duration= {500}>
                                <p className="about">
                                    명운세 소개
                                </p>
                           </Link>
                        </li>
                        <li>
                            <Link
                            activeClass="active"
                            to="Section5"
                            spy={true}
                            smooth={true}
                            offset={-50}
                            duration= {500}>
                                <p className="pay">
                                    이용 요금
                                </p>
                            </Link>
                        </li>
                         <li>
                             <a
                                href='https://docs.google.com/forms/d/e/1FAIpQLSd567w2dqf6d6Ue-vg6tJPRTD-ZNI0eFLYcENo0sBARn90lYw/viewform?usp=send_form'
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "#191919", textDecoration: "none"}}>
                                  <p className="master">
                                      전문가 채용
                                  </p>
                             </a>
                         </li>
                    </ul>
                </div>
             </div>
            </nav>
            </div>
            </div>
            </div>
        </div>
    
)
}
}
export default Sidebar;



