import React, { Component } from 'react';
import Index from "./pages/Main";



class App extends Component {
  render() {
    return (
     <Index />
   
);         
  }
}

export default App;

