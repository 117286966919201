import React  from 'react';
import '../../assets/css/base/section3.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import Data2 from "../../Component/Data/Data2";
import { useScrollFadeIn } from "../../hooks";

const Section3 = () => {
    const animatedItem = {
        0: useScrollFadeIn('up', 1, 0),
        1: useScrollFadeIn('up', 1, 0),
        2: useScrollFadeIn('up', 1, 0.6),
        3: useScrollFadeIn('up', 1, 0.9),
    };

    return (
        <div className="size">

            <div className="section3">

                <div className="section-contents">
                    <div>
                        <div className="contents-top-title">
                            <h1
                                {...animatedItem[0]}
                            >
                                전국에서 소문난<br/>
                                운세전문가들이 기다려요
                            </h1>
                        </div>
                        <div className="contents-top-text">
                            <p  {...animatedItem[1]}>
                                검증된 사주, 타로, 신점, 관상, 손금 전문가와<br/>
                                함께 고민을 해결해보세요.
                            </p>
                        </div>
                    </div>
                    <div className="contents-img">
                        <div className="desktop_tablet">
                            <Data2/>
                        </div>
                        <div className="tablet">
                            <Data2/>
                        </div>
                        <div className="mobile">
                            <Data2/>
                        </div>
                    </div>
                    <div className="contents-left">
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Section3;