import React, { Component } from 'react';
import '../../assets/css/base/section6.css'
import '../../assets/css/base/section7.css'
import appicon from '../../assets/img_update/app.png'
import appdownload from '../../assets/img/download-btn-w@3x.png'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'


function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    if (/iPad|iPhone|iPod|Safari/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}


function DetectAndServe() {
    if (getMobileOperatingSystem() == "Android") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
    }
    if (getMobileOperatingSystem() == "iOS") {
        // window.location.href = "itms-apps://itunes.apple.com/app/id1441749378";
        window.location.href = "https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EB%AC%B8-for-%ED%83%80%EB%A1%9C%EB%A7%88%EC%8A%A4%ED%84%B0/id1409500842"
    }
    if (getMobileOperatingSystem() == "Windows Phone") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
    }
    if (getMobileOperatingSystem() == "unknown") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
    }
};



class Section7 extends Component {

    render(){
        return(
            <div className="size">
                <div className="section7">
                    <div className="section-contents">
                        <div className="contents-top">
                            <div className="contents-mus">
                                <div className="contents-mus-text">
                                    <img src={appicon}
                                         alt="appicon"
                                         className="appicon"
                                    />
                                    <h4>
                                        나의 미래를 밝혀줄 명운세<br/>
                                        지금 바로 다운로드하세요.
                                        </h4>
                                </div>
                            </div>
                        </div>
                        <div className="contents-bottom">

                            <div className="contents-app-down">
                                <div className="contents-app-text">
                                    <a href="https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload">
                                        <div className="googleplay_button googleplay-bottom"/>
                                      </a>
                                    <a href="https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EB%AC%B8-for-%ED%83%80%EB%A1%9C%EB%A7%88%EC%8A%A4%ED%84%B0/id1409500842">
                                        <div className="appstore_button appstore-bottom"/>
                                    </a>
                                    <img src={appdownload} alt="appdownload" className="appdownload" onClick={DetectAndServe}/>
                                </div>
                            </div>

                            <div className="mobile">
                                <div className="mobile_app_download" onClick={DetectAndServe}/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Section7;
