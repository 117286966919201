import React, { useState } from 'react';
import Lottie from 'react-lottie-player';
import data from '../JSON/section3/data3';



const Data6 = () => {
    return (
        <div
            className="thumbs-up sectionimg1"
            id="lottie"
        >
            <Lottie
                loop={true}
                animationData={data}
                play
                renderer='svg'
            />

        </div>
    )
};

export default Data6;