import React, { Component } from 'react';
import '../../assets/css/base/section4.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import coupon from '../../assets/img_update/bg-2-event-desktop-1920.svg';

class Section6 extends Component {

    render(){
        return(
            <div className="size">
                <div className="section6">
                    <div className="section-contents">
                        <div className="contents-left">
                            <img src={coupon} className="coupon_img"/>
                        </div>
                        <div className="contents-right" >

                                <div className="contents-coupon desktop">
                                    <h1>
                                        지금 명운세에 가입하면<br/>
                                        <strong>무료상담 1회</strong>와<br/>
                                        <strong>50% 할인쿠폰</strong>을 드려요!
                                    </h1>
                                </div>
                            <div className="contents-coupon tablet">
                               <h1> <p>지금 명운세에 가입하면</p>
                                   <strong>무료상담 1회</strong>와<strong> 50% 할인쿠폰</strong>을 드려요!</h1>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Section6;