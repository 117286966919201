import React, { Component } from 'react';
import '../../assets/css/base/footer.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'


function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;


    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: https://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod|Safari/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    return "unknown";
}


function DetectAndServe() {
    if (getMobileOperatingSystem() === "Android") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
    }
    if (getMobileOperatingSystem() === "iOS") {
        // window.location.href = "itms-apps://itunes.apple.com/app/id1441749378";
        window.location.href = "https://apps.apple.com/kr/app/%ED%83%80%EB%A1%9C%EB%AC%B8-for-%ED%83%80%EB%A1%9C%EB%A7%88%EC%8A%A4%ED%84%B0/id1409500842"
    }
    if (getMobileOperatingSystem() === "Windows Phone") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
    }
    if (getMobileOperatingSystem() === "safari") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
    }
    if (getMobileOperatingSystem() === "unknown") {
        window.location.href = "https://play.google.com/store/apps/details?id=com.moditt.myungwoonse&referrer=utm_source%3Dmunse%26utm_medium%3Dcpc%26utm_term%3Dmunse%26utm_content%3Ddownload_btn%26utm_campaign%3Ddownload";
    }
};


class Footer extends Component {

    render(){
        return(
            <div className="size">
                <div className="footer solid">
                    <div className="footer-contents">
                        <div className="footer-contents-info">
                            <div className="footer-title-text">
                                <h1>미래를 밝혀주는 운세상담 서비스, 명운세</h1>
                            </div>

                            <div className="footer-desktop">
                                <div className="footer-moditt">
                                    <h2>주식회사 모딧</h2><div className="footer-moditt-h2"/><h2>대표: 권영국</h2><h2 className="footer-moditt-h2"/><h2>전화번호: 02-6956-7992</h2>
                                </div>
                                <div className="footer-moditt">
                                    <h2>주소: 서울특별시 강남구 논현로 98길 306호</h2>
                                </div>
                                <div className="footer-moditt">
                                    <h2>이메일: contact@munse.co.kr</h2>
                                </div>
                                <div className="footer-moditt">
                                    <h2>사업자등록번호: 202-86-00196</h2>
                                </div>
                            </div>

                            <div className="footer-contents-box">
                                <div className="footer-text">

                                    <div className="user">
                                        <h5>
                                            <a href='https://munse.co.kr/webview/privacy.html'
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               style={{ color: "#191919", textDecoration: "none" }}>
                                                개인정보처리방침
                                            </a>
                                        </h5>
                                        <h5>
                                            <a href='https://munse.co.kr/webview/user.html'
                                               target="_blank"
                                               rel="noopener noreferrer"
                                               style={{ color: "#191919", textDecoration: "none" }}>
                                                개인정보 제3자 제공 및 위탁
                                            </a>
                                        </h5>
                                        <div className="naver_form_line">
                                            <h5>
                                                <a href='https://munse.co.kr/webview/terms.html'
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                   style={{ color: "#191919",textDecoration: "none" }}>
                                                    이용약관
                                                </a>
                                            </h5>
                                            <h5 className="naver_form">
                                                <a href='https://docs.google.com/forms/d/e/1FAIpQLSd567w2dqf6d6Ue-vg6tJPRTD-ZNI0eFLYcENo0sBARn90lYw/viewform?usp=send_form'
                                                   target="_blank"
                                                   rel="noopener noreferrer"
                                                   style={{ color: "#191919", textDecoration: "none"}}>
                                                    전문가 채용문의
                                                </a>
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div className="sns_link">
                                    <a href='https://www.instagram.com/myoungunse/'
                                       rel="noopener noreferrer"
                                       target="_blank">
                                        <div className="snsBox">
                                            <div className="instagram sns" />
                                        </div>
                                    </a>
                                    <a href='https://blog.naver.com/m_unse'
                                       rel="noopener noreferrer"
                                       target="_blank">
                                        <div className="snsBox padding_left">
                                            <div className="blog sns" />
                                        </div>
                                    </a>
                                </div>

                                <div className="footer-mobile">
                                    <div className="footer-moditt">
                                        <h2>주식회사 모딧</h2><div className="footer-moditt-h2"/><h2>대표: 권영국</h2><h2 className="footer-moditt-h2"/><h2>전화번호: 02-6956-7992</h2>
                                    </div>
                                    <div className="footer-moditt">
                                        <h2>주소: 서울특별시 강남구 논현로 98길 306호</h2>
                                    </div>
                                    <div className="footer-moditt">
                                        <h2>이메일: contact@munse.co.kr</h2>
                                    </div>
                                    <div className="footer-moditt">
                                        <h2>사업자등록번호: 202-86-00196</h2>
                                    </div>
                                </div>

                                <h5 className="copyright">
                                    Copyright © 2019 Moditt Corporation. All rights reserved. <br/>
                                    기업부설연구소 및 벤처 인증 기업
                                </h5>
                                <h5 className="mobile-copyright">
                                    Copyright © 2019 Moditt Corporation. All rights reserved. <br/>
                                    기업부설연구소 및 벤처 인증 기업
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="event">
                        <div className="event_box">
                            <div className="event_text">
                                <p>
                                    지금 명운세에 가입하면 <br/>
                                    첫 구매 50% 할인쿠폰을 드려요.
                                </p>
                            </div>
                            <div className="event_down"
                                 onClick={DetectAndServe}>
                                <p>다운로드</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )



    }
}

export default Footer;