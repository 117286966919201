import React from 'react';
import { useScrollFadeIn} from "../../../hooks";

const Section4Tablet = () => {
    const animatedItem = {
        0: useScrollFadeIn('up', 1, 0),
        1: useScrollFadeIn('up', 1, 0),
        2: useScrollFadeIn('up', 1, 0.6),
        3: useScrollFadeIn('up', 1, 0.9),
    };
    return (
        <React.Fragment>
            <div>
                <h1 {...animatedItem[0]}>
                    정확하게 꿰뚫는<br/>
                    오늘의 운세
                </h1>
            </div>
            <p className="top-contetns" {...animatedItem[1]}>
                하루가 평탄하게 지나갈 수 있도록<br/>
                명운세가 힘이 되어드릴게요.
            </p>
        </React.Fragment>
    );
};

export default Section4Tablet;