import React from 'react';
import '../../assets/css/base/section4.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import Data3 from "../../Component/Data/Data3";
import Section4Contents from "../../Component/Section/Section4/Section4Contents";
import Section4Tablet from "../../Component/Section/Section4/Section4Tablet";

const Section4 = () => {


    return (
        <div className="size">
            <div className="section4">
                <div className="section-contents"
                >
                    <div className="desktop" >
                        <div className="contents-left"  >
                            <Data3 />
                        </div>
                        <div className="contents-right"
                        >
                            <div className="contents-text-black">
                                <div className="contents-base" >
                                  <Section4Contents/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tablet">
                        <div className="contents-right">
                            <div className="contents-text-black">
                                <div className="contents-base">
                                    <Section4Tablet/>
                                </div>

                            </div>
                        </div>
                        <div className="contents-left desktop_tablet" >
                            <Data3/>
                        </div>
                        <div className="contents-left mobile" >
                            <Data3/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Section4;