import React from 'react';
import '../../assets/css/base/section5.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import Data4 from "../../Component/Data/Data4";
import { useScrollFadeIn} from "../../hooks";

const Section5 = () => {
    const animatedItem = {
        0: useScrollFadeIn('up', 1, 0),
        1: useScrollFadeIn('up', 1, 0),
        2: useScrollFadeIn('up', 1, 0.6),
        3: useScrollFadeIn('up', 1, 0.9),
    };
    return (
    <div className="size">
        <div className="section5">
            <div className="section-contents"
            >
                <div className="contents">
                    <div className="contents-left" >
                        <div className="contents-text-black">
                            <div className="contents-base">
                                <h1 {...animatedItem[0]}>
                                    만족스러운<br/>
                                    상담내용과 상담비용
                                </h1>
                                <p className="top-contetns"
                                   {...animatedItem[1]}
                                >
                                    타사 대비 최대 40% 저렴하여<br/>
                                    실속있는 상담을 받을 수 있습니다.
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="contents-right desktop_tablet" >
                        <Data4/>
                    </div>
                    <div className="contents-right mobile">
                        <Data4/>
                    </div>
                </div>
            </div>
        </div>
    </div>

);
};

export default Section5;