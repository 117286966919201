import React, { Component } from 'react';
import '../../assets/css/base/section2.css'
import '../../assets/css/tablet/tablet.css'
import '../../assets/css/mobile/mobile.css'
import '../../assets/css/mobile/mobile_320.css'
import '../../assets/css/desktop/desktop.css'
import '../../assets/css/desktop/desktop.css'
import Data1 from "../../Component/Data/Data1";
import Data5 from "../../Component/Data/Data5";
import Data6 from "../../Component/Data/Data6";
import Section2Component from "../../Component/Section/Section2/Section2Component";


class Section2 extends Component {
    constructor(props) {
        super(props);
           this.state = {
               select_type:'chat',
               isInView: false
           };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (e) => {
    this.setState({
        select_type: 'chat'
    })
};
    handleVoiceClick = (e) => {
        this.setState({
            select_type: 'voice'
        })
    };

    handleVideoClick = (e) => {
        this.setState({
            select_type: 'video'
        })
    };



    render(){
        const { select_type } = this.state;

        let UnseMain_desktop = 'chat';
        if (select_type === 'voice') {
            UnseMain_desktop = <Data5/>

        } else if (select_type === 'video') {
            UnseMain_desktop = <Data6/>
        } else {
            UnseMain_desktop =
                <div>
                  <Data1/>
                </div>
        }

        let UnseMain_mobile = 'chat';
        if (select_type === 'voice') {
            UnseMain_mobile = <Data5/>

        } else if (select_type === 'video') {
            UnseMain_mobile = <Data6/>
        } else {
            UnseMain_mobile =  <Data1/>
        }

        let UnseMain_tablet = 'chat';
        if (select_type === 'voice') {
            UnseMain_tablet =
             <Data5/>

        } else if (select_type === 'video') {
            UnseMain_tablet = <Data6/>
        } else {
            UnseMain_tablet = <Data1/>
        }



    return(
    <React.Fragment>
        <Section2Component
            select_type={this.state.select_type}
            UnseMain_desktop={UnseMain_desktop}
            handleClick={this.handleClick}
            handleVideoClick={this.handleVideoClick}
            handleVoiceClick={this.handleVoiceClick}
            UnseMain_mobile={UnseMain_mobile}
            UnseMain_tablet={UnseMain_tablet}
        />
    </React.Fragment>
)
}
}

export default Section2;